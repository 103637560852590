@import './../../../../scss/theme-bootstrap';

// Only apply to grids that have formatter sidecar enable
.basic-grid-formatter.sidecar-formatter {
  .basic-grid-formatter {
    &__content-region {
      padding: 0 10px;
    }
    &__content-wrapper {
      width: 100%;
      // use bg element
      background-image: none !important;
      margin-top: 25px;
      @include breakpoint($medium-up) {
        margin: 50px 0 100px 0x;
      }
    }
  }
}
